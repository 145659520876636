import * as React from "react"
import { connect } from "react-redux"
import { graphql, Link, navigate } from "gatsby"

import Filter from "../components/filter"
import Layout from "../components/layout"
import Seo from "../components/seo"

import "./videos.css"

export const query = graphql`query VideoList{
  allMdx(
    sort: { fields: [frontmatter___releaseDate], order:DESC }
    filter: {fileAbsolutePath: {regex: "/\/src\/videos\//"}}
  ) {
    edges{
      node{
        frontmatter {
          slug
          sku
          title
          image
          summary
          releaseDate(formatString: "MMMM DD, YYYY")
          fighters
        }
        excerpt
      }
    }
    distinct(field: frontmatter___fighters)
  }
}`
        
const VideosPage = ({data, fighterFilters, setFighterFilters}) => {
  const { allMdx } = data;
  const videos = allMdx.edges;
  const fighters = allMdx.distinct;

  const subtitle = "Fight Vids"
  const tagline = "Scripted female fighting at it's finest."
  //"The hottest young ladies get it on in wrestling, fistfighting, boxing, and good ol' fashion catfighting action."

  const nav = (dest) => {
    return (event) => {
      event.preventDefault()

      navigate(dest)
    }
  }

  const renderVideos = (videos) => {
    return (
      <div className="vid-list">
        {videos.filter( ({node}) => (
          fighterFilters.size === 0 ||
          node.frontmatter.fighters.filter(x => fighterFilters.has(x)).length > 0
        )).map( ({node}, index) => {
          const {slug, sku} = node.frontmatter;

          return (
            <article className="vid-item" key={sku} onClick={nav(slug)}>
              <img src={node.frontmatter.image} alt="" />
              <div className="vid-text">
                <div className="vid-sku">{node.frontmatter.sku}</div>
                <header>
                  <Link to={slug}><h3 className="vid-title">{node.frontmatter.title}</h3></Link>
                </header>
                <p>{node.frontmatter.summary}</p>
              </div>
            </article>
          );
        })}
      </div>
    );
  }

  return (
    <Layout subtitle={subtitle} tagline={tagline} isWide={true}>
      <Seo title="Videos" />

      <Filter fields={fighters} filters={fighterFilters} setFilters={setFighterFilters}></Filter>

      {renderVideos(videos)}

    </Layout>
  )
}

const mapStateToProps = ({fighterFilters}) => {
  return { fighterFilters };
}

const setFighterFilters = filters => ({
  type: 'SET_STATE_VALUE',
  payload: { fighterFilters: filters }
});

const mapDispatchToProps = dispatch => {
  return {
    setFighterFilters: async filters => {
      try {
        dispatch(setFighterFilters(filters));
      } catch(err) {
        console.log(err);
      }
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideosPage)
